import React, { Fragment, useState,useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Link, useHistory } from 'react-router-dom/cjs/react-router-dom'
import { addDoc, collection, doc, setDoc,query,where,onSnapshot, serverTimestamp } from 'firebase/firestore';
import { auth, fireStore } from '../firebase';
import {createUserWithEmailAndPassword, sendEmailVerification} from "firebase/auth"
import Navigation from '../views/Navigation';

export default function Register() {
    const [navPop,setNavPop] = useState(false)
    const [password,setpassword] = useState('')
    const [email,setemail] = useState('')
    const [name,setName] = useState('')
    const [nameError,setNameError] = useState('')
    const [duplicate,setduplicate] = useState(false)
    const [isLoading,setIsLoading] = useState(false)
    const [matchedError,setmatchedError] = useState('')
    const [matched,setmatched] = useState(false)
    const [passwordError,setpasswordError] = useState("")
    const [emailError,setemailError] = useState('')
    const [confirmPassword,setconfirmPassword] = useState("")
    const [confirmPasswordError,setconfirmPasswordError] = useState("")
    const [registrationError,setregistrationError] = useState("")

  const history = useHistory()


    useEffect(() => {
        
      (async()=>{
    
        const q = query(
          collection(fireStore, "TotalUsers"),
          where("email", "==", email)
        );
      if(email || email){
        onSnapshot(q,snapshot=>{
          snapshot.forEach((doc) => {
            
            if(doc.exists ){
              setduplicate(true)
            }
    
            else {
              setduplicate(false)
            }
           
     })
    
        });
      }
     
      })
     ()
    }, [email,email]);

    const signUp = (e) => {
      e.preventDefault();
    
      // Initialize a variable to track if there are any errors
      let hasError = false;
      // Validate the name field
      if (name.length === 0) {
        setNameError('error');
        hasError = true;
        window.scrollTo(0, 0); // Scroll to the top to show the error
      } else {
        setNameError('');
      }
    
      // Validate email field and check if it's a duplicate
      if (email.length === 0 ) {
        setemailError('error');
        hasError = true;
      } else {
        setemailError('');
      }
    
      // Validate password field
      if (password.length === 0) {
        setpasswordError('error');
        hasError = true;
      } else {
        setpasswordError('');
      }
    
      // Validate confirm password
      if (confirmPassword.length === 0 || password !== confirmPassword) {
        setconfirmPasswordError('error');
        hasError = true;
      } else {
        setconfirmPasswordError('');
      }
    
      // If any error is found, exit the function
      if (hasError) return;
    
      // If all validations passed, proceed with user creation
      setIsLoading(true);
    
      createUserWithEmailAndPassword(auth, email, password)
        .then((credential) => {
          // Add the user to Firestore after successful authentication
          return setDoc(doc(fireStore, "TotalUsers", credential.user.uid), {
            fullName: name,
            email: email,
            password: password,
            photo: '',
            uid: credential.user.uid,
            createdTime: serverTimestamp(),
          });
        })
        .then(() => {
          // Redirect to the collection page after successful signup
          history.push("/collection");
        })
        .catch((error) => {
          console.log(error);
          // Handle specific error cases
          if (error.code === "auth/email-already-in-use") {
            setregistrationError('Duplicate account found');
          } else if (error.code === "auth/invalid-login-credentials") {
            setregistrationError('Invalid login credentials');
          } else if (error.code === "auth/network-request-failed") {
            setregistrationError('Check your Internet Connection');
          } else if (error.code === "auth/invalid-email") {
            setregistrationError('Invalid email address');
          } else {
            setregistrationError(error.message);
          }
        })
        .finally(() => {
          // Stop loading after the process finishes, either successfully or with an error
          setIsLoading(false);
        });
    };
    
    function validateEmail(email) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }
    const nameSet = (e)=>{
      
      setName(e.target.value)
      if(e.target.value.length == 0){
        setNameError("error")
      }
      else {
        setNameError("")

      }

    }

    const emailSet = (e)=>{
      setduplicate(false)
      setemail(e.target.value)
      if(e.target.value.length == 0){
        setemailError("error")
      }
      else {
        setemailError("")
        if (validateEmail(e.target.value)) {
          setemailError("")
  
          } else {
          setemailError("error")
  
          }

      }

    }

    const passwordSet = (e)=>{

      setpassword(e.target.value)
      if(e.target.value.length <= 7){
        setpasswordError("error")
      }
      else {
        setpasswordError("")

      }
      
   

    }
    const confirmPasswordSet = (e)=>{
      setconfirmPassword(e.target.value)
      if(e.target.value != password){
        setconfirmPasswordError("error")
      }
      else {
        setconfirmPasswordError("")

      }

    }




    function navPopHandler(){
        if(navPop === true){
          setNavPop(false)
        }
        else{
          setNavPop(true)
        }
      } 
  return (
    
    <div className=''>
          <Helmet>
          <title>Register - Ephratawi</title>

<meta property="og:title" content="Ephratawi Design" />
      </Helmet>
<Navigation navigationPop={navPop} component={'Register'}/>
      
 
    <div className="shadow-md rounded-md border mx-auto my-20 login_section" style={{backgroundColor:'white',padding:'20px'}}>
      <div className="" >
        <div className="contact-form5-section-title thq-flex-column" >
          <span className="thq-body-small">
               <span 
                      style={{fontSize:'30px',marginTop:'20px',marginBottom:'40px',textAlign:'center',display:'flex',justifyContent:'center'}}       className="nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 text-white weight-medium uppercase"
                      >Create an account</span>
          </span>
         

          
        </div>
        <div className={`${registrationError.length != 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'12px'}}>
{registrationError}
</div>
</div>
</div>


<form class="max-w-md mx-auto" style={{marginTop:'20px'}}>
  
<div class="relative z-0 w-full mb-5 group">
      <input onChange={e=>nameSet(e)}type="text" autocomplete="off"name="floating_name" id="floating_name" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="input-signUp block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
      <label for="floating_name" class=" peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Full Name</label>
      <div className={`${nameError.length != 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'12px'}}>
Name Can't Be empty
</div>
</div>
</div>
  </div>
  <div class="relative z-0 w-full mb-5 group">
      <input onChange={e=>emailSet(e)}type="email" autocomplete="off"name="floating_email" id="floating_email" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="input-signUp block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
      <label for="floating_email" class=" peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
      <div className={`${emailError.length != 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'12px'}}>
Email Can't Be empty
</div>
</div>
</div>
  </div>



    <div onChange={e=>passwordSet(e)}class="relative z-0 w-full mb-5 group">
        <input type="password"  autocomplete="off"name="floating_password" id="floating_password" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="input-signUp block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
        <label for="floating_password" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7  scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password </label>
        <div className={`${passwordError.length != 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'12px'}}>
Password Can't Be empty
</div>
</div>
</div>
    </div>
    <div onChange={e=>confirmPasswordSet(e)}class="relative z-0 w-full mb-5 group">
        <input type="password"  autocomplete="off"name="floating_password" id="floating_password" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="input-signUp block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
        <label for="floating_password" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7  scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Confirm Password </label>
        <div className={`${confirmPasswordError.length != 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'12px'}}>
Passwords don't match
</div>
</div>
</div>
    </div>
   
              <button onClick={signUp} className="button-secondary button bg-transparent">Register</button>

             
</form>

      </div>
    </div>
    </div>
  )
}
