import React, { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'
import {useNavigate,Link} from 'react-router-dom'
import { useHistory } from 'react-router-dom/cjs/react-router-dom'
import { sendPasswordResetEmail, signInWithEmailAndPassword } from 'firebase/auth'
import { addDoc, collection, doc, setDoc,query,where,onSnapshot } from 'firebase/firestore';
import { auth } from '../firebase'
import Navigation from '../views/Navigation'

export default function Login() {
    const [navPop,setNavPop] = useState(false)
    const [email,setEmail] = useState("")
    const [password,setPassword] = useState("")
    const [emailError,setEmailError] = useState("")
    const [registrationError,setregistrationError] = useState("")
    const [forgotPassword,setforgotPassword] = useState(false)
    const [sent,setsent] = useState(false)
    const [loading,setloading] = useState(false)
    const [spinner,setspinner] = useState(true)
    const [isLoading,setIsLoading] = useState(false)

    const[forgotPasswordError,setforgotPasswordError] = useState('')
    const[forgotPasswordEmail,setforgotPasswordEmail] = useState('')
    const[resetEmailError,setresetEmailError] = useState('')
    const [duplicate,setduplicate] = useState(false)
    const [resetEmailModal,setresetEmailModal] = useState(false)










    const [passwordError,setPasswordError] = useState("")
    const history = useHistory()

    function navPopHandler(){
        if(navPop === true){
          setNavPop(false)
        }
        else{
          setNavPop(true)
        }
      } 
      function resetEmail(){
        if (forgotPasswordEmail.length === 0) {
          setforgotPasswordEmail('error');
        } else {
          setIsLoading(true);
      
          sendPasswordResetEmail(auth, forgotPasswordEmail)
            .then(() => {
              setIsLoading(false);
              setsent(true);
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              setresetEmailError(errorMessage);
              setIsLoading(false);
            });
        }   if (forgotPasswordEmail.length === 0) {
          setforgotPasswordError('error');
        } else {
          setIsLoading(true);
      
          sendPasswordResetEmail(auth, forgotPasswordEmail)
            .then(() => {
              setIsLoading(false);
              setsent(true);
            })
            .catch((error) => {
              const errorCode = error.code;
              const errorMessage = error.message;
              setresetEmailError(errorMessage);
              setIsLoading(false);
            });
        }
    }
  
    function successRemove(){
      setsent(false)
      setforgotPassword(false)
      setresetEmailModal(false)
    }
    function resetEmailSet(e){
      setforgotPasswordEmail(e.target.value)
      if(e.target.value.length === 0){
       setforgotPasswordError('error')

      }
      else{
       setforgotPasswordError('')
      }
    }

    function openForgotPassword(){
          setforgotPassword(true)
          setresetEmailModal(true)

    }
      function emailSet(e){
        setEmail(e.target.value)

        if(e.target.value.length == 0){
            setEmailError("error")
        }

        else{
            setEmailError("")
            
        }

    }

    function passwordSet(e){
        setPassword(e.target.value)

        if(e.target.value.length == 0){
            setPasswordError("error")
        }

        else{
            setPasswordError("")
            
        }

    }
    function signIn(e) {
        e.preventDefault();
        if(email.length === 0){
          setEmailError('error')
        }
        else{
          if(password.length === 0){
            setPasswordError('error')

          }
          else{
            if(email.length !=0 && password.length !=0){
              signInWithEmailAndPassword(auth, email, password).then(setIsLoading(true))
              .then(() => {
                history.push("/collection");
            })
            .catch((error) => {
              setIsLoading(false)
                // Handle specific error cases
                if (error.code === "auth/user-not-found") {
                    setregistrationError('User not found.');
                } else if (error.code === "auth/invalid-credential") {
                  setregistrationError('Invalid login credentials');
              } 
                
                else if (error.code === "auth/invalid-login-credentials") {
                    setregistrationError('Invalid login credentials');
                } else if (error.code === "auth/network-request-failed") {
                  setregistrationError('Check your Internet Connection');
      
                }
                else if (error.code === "auth/invalid-email") {
                  setregistrationError('Invalid login credentials');
      
                }
                 else {
                    setregistrationError(error.message);
                }
            });
             }
          }

        }
      
      
       
    }
  return (
    
    <div className=''>
          <Helmet>
          <title>Log In - Ephratawi</title>

<meta property="og:title" content="Ephratawi Design" />
      </Helmet>
<Navigation navigationPop={navPop} component={'Login'}/>
    <div className="shadow-md rounded-md border mx-auto mt-20 login_section" style={{backgroundColor:'white',padding:'20px'}}>
      <div className="" >
        <div className="contact-form5-section-title thq-flex-column" >
          <span className="thq-body-small">
               <span 
                      style={{fontSize:'30px',marginTop:'20px',marginBottom:'40px',textAlign:'center',display:'flex',justifyContent:'center'}}       className="nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 text-white weight-medium uppercase"
                      >Sign in to your account</span>
          </span>
         

          
        </div>
{
  resetEmailModal && 
  <div className='signInpop ' style={{zIndex:1001}}>
        
        {
   isLoading === true ?
   <div style={{marginTop:'20px'}} class="bg-white max-w-md mx-auto flex items-center justify-center shadow border rounded  w-full p-10 ">
   
   <div
   className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
   role="status"></div></div>
   :
   sent ===  true?
   <div style={{width:'40%'}}class="bg-white shadow border rounded  w-full p-10 ">
                <div  id='successCourseAddDesktop' style={{display:'flex',justifyContent:'center',alignItems:'center'}} className='flex items-center justify-center'>


<div className="relative p-4 w-full  "style={{width:"100%"}}>
<div className="relative p-4 text-center bg-white rounded-lg   sm:p-5" >
 
   <button  onClick={successRemove} type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
       <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Close modal</span>
   </button>


   <div className="w-12 h-12 rounded-full bg-green-400 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
       <svg aria-hidden="true" className="w-8 h-8 text-white " fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Success</span>
   </div>
   <p className="mb-2 text-lg font-semibold text-gray-900  capitalize css-7vq5hu" style={{fontSize:'30px'}}>Email has been sent</p>
                <p className='borel'>Please check your email inbox for a password recovery link</p>
</div>
</div>



</div> 
</div>
:
   forgotPassword === true &&
   <div style={{width:'40%'}}class="bg-white shadow border rounded  w-full p-10 relative">
       <button  onClick={successRemove} type="button" className="text-gray-400 absolute top-2.5 right-2.5 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white" data-modal-toggle="successModal">
       <svg aria-hidden="true" className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
       <span className="sr-only">Close modal</span>
   </button>
<div className={`${resetEmailError.length !== 0 ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{resetEmailError}
</div>
</div>
</div>
<p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800">Reset Password</p>



<div className="relative mx-auto mt-12" style={{width:'80%'}}>
<p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Email</p>
<input onChange={(e)=>resetEmailSet(e)}style={{fontSize:'12px',height:'60px'}}   required  autoComplete="off" placeholder="kebede@gmail.com" type="email" className="border  focus:outline-none
focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
border-gray-300 rounded-md input-field-reset" />

<div className={`${forgotPasswordError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Email Can't be empty
</div>
</div>
</div>
</div>

<div className="flex justify-center mt-12 mx-auto" style={{width:'80%'}}>
<button
type='button'
onClick={resetEmail}
style={{fontSize:'12px',margin:'auto',background:'#31ffaa',height:'55px'}}
className='flex justify-center items-center par border w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
>
Reset Password
</button>
</div>
<div class="w-full flex items-center justify-between py-5 mt-4">
</div>



</div>

}
</div>
}

<form class="max-w-md mx-auto" style={{marginTop:'20px'}}>
 <div className={`${emailError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4  text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'12px'}}>
Email Can't be empty
</div>
</div>
</div>
<div className={`${passwordError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'12px'}}>
Password Can't be empty
</div>
</div>
</div>
<div className={`${registrationError ? "block":"hidden"}`}>
<div className="flex items-center p-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli' style={{fontSize:'12px'}}>
{registrationError}
</div>
</div>
</div>
  <div class="relative z-0 w-full my-5 group">
      <input onChange={e=>emailSet(e)} type="email" name="floating_email" id="floating_email" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="input-signUp block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
      <label for="floating_email" class=" peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7 scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Email address</label>
      <div className={`${emailError === "error" ? "block":"hidden"}`}>

</div>
  </div>
  


    <div class="relative z-0 w-full mb-5 group">
        <input type="password" onChange={e=>passwordSet(e)} name="floating_phone" id="floating_phone" style={{borderBottom:'1px solid black',borderRadius:'0',marginBottom:'20px'}}class="input-signUp block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent  border-gray-300 appearance-none   focus:outline-none focus:ring-0 focus:border-green-600 peer" placeholder=" " required />
        <label for="floating_phone" class="peer-focus:font-medium absolute text-sm text-gray-500  duration-300 transform -translate-y-7  scale-75 top-3 -z-10 origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-green-600  peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6">Password </label>
    </div>
  
   
              <button onClick={signIn} type="submit" className="button-secondary button bg-transparent">Sign In</button>

              <p onClick={openForgotPassword} style={{fontSize:'12px'}} className='cursor-pointer mt-5 flex justify-center '>
                    Forgot Password?
                     
                      
                    </p>
</form>

      </div>
    </div>
    </div>
  )
}
