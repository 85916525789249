// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration

import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDvvU9TUQiSIm-iV5VzhdBNxzIMnHZRZYc",
  authDomain: "rimmon-fc2b6.firebaseapp.com",
  projectId: "rimmon-fc2b6",
  storageBucket: "rimmon-fc2b6.firebasestorage.app",
  messagingSenderId: "853405418157",
  appId: "1:853405418157:web:91115e504ce736a0d43c24"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export let fireStore = getFirestore(app)
export let auth  = getAuth()
export let storage  = getStorage()

