import { collection, serverTimestamp, writeBatch, doc, getDocs, deleteDoc, setDoc } from 'firebase/firestore';
import React, { useState } from 'react';
import { fireStore } from '../firebase';

const PaymentComponent = ({
  phoneNumber,
  email,
  uniqueId,
  cart,
  accountHolder,
  accountNumber,
  afterDiscount,
  totalPrice,
  deliveryDate,
  uid,
  contactNumber,
  fullName,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  
  const handlePayment = async () => {
    if (loading) return; // Prevent duplicate clicks
    setLoading(true);
    setError(null);
  
    const nameParts = fullName.trim().split(' ');
    const paymentData = {
      amount: afterDiscount || totalPrice,
      currency: 'ETB',
      email: email,
      first_name: nameParts[0],
      last_name: nameParts[nameParts.length - 1],
      phone_number: phoneNumber,
      tx_ref: uniqueId,
      deliveyDate:deliveryDate,
      clothes: cart,

      return_url:`https://ephratawi.top/collection/${uniqueId}/checkout`,
      callback_url: `https://ephratawi.top/collection/${uniqueId}/checkout`,
    };
  
    const transaction = {
      clothes: cart,
      transactionId: uniqueId,
      totalAmount:totalPrice,
      deliveryDate,
      time: serverTimestamp(),
      userId: uid,
      status: 'pending',
      phoneNumber: contactNumber,
      afterDiscount:afterDiscount ? afterDiscount :''
    };
  
    try {
      // Step 1: Make payment request
      const response = await fetch('https://mellifluous-khapse-6e5af3.netlify.app/.netlify/functions/api/payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentData),
        credentials: 'include'  // Ensure credentials (like cookies) are sent if needed
      });
    
      const result = await response.json();
      console.log('Chapa API Response:', result); // Log the Chapa response
    
      if (!result?.data?.checkout_url) {
        throw new Error('Invalid payment response');
      }
    
      // Step 2: Firestore operations
      const cartCollection = collection(fireStore, 'TotalUsers', uid, 'Cart'); // Reference to the Cart collection
      const transactionDoc = doc(fireStore, 'TotalUsers', uid, 'order', uniqueId);
      const transactionDoc2 = doc(fireStore, 'Orders', uniqueId);
    
      // Add transaction details
      await setDoc(transactionDoc, transaction, { merge: true });
      await setDoc(transactionDoc2, transaction, { merge: true });
    
      // Fetch and delete all documents in the Cart collection
      const cartDocs = await getDocs(cartCollection); // Fetch documents
      // const deletePromises = cartDocs.docs.map((doc) => deleteDoc(doc.ref)); // Create delete promises
      // await Promise.all(deletePromises); // Execute all deletions
    
      // Redirect to the payment checkout URL
      window.location.href = result.data.checkout_url;
    } catch (err) {
      console.error('Payment error:', err);
      setError(`Failed to process payment. ${err.message || 'Please try again.'}`);
    } finally {
      setLoading(false);
    }
    
  };

  return (
    <div>
     
      <button
                   type='button'
                   onClick={handlePayment} disabled={loading}
                   style={{fontSize:'12px',margin:'auto',background:'black',color:'white',height:'55px'}}
                   className='flex justify-center items-center header border shadow  w-full text-gray-800  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                 >
                         {loading ? 'Processing...' : 'Make Payment'}

                 </button>

      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default PaymentComponent;


