import React, { useEffect, useState } from 'react'

import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import { collection, onSnapshot, query, orderBy } from 'firebase/firestore';
import { auth, fireStore } from '../firebase';

import Card from '../components/card'
import './home.css'
import Footer from './Footer';
import Navigation from './Navigation';

const Home = (props) => {
  const [navPop,setNavPop] = useState(false)
  const [clothes, setclothes] = useState([]);
  const [currentIndexes, setCurrentIndexes] = useState({});
  const [isLoading, setisLoading] = useState(true);

  function navPopHandler(){
    if(navPop === true){
      setNavPop(false)
    }
    else{
      setNavPop(true)
    }
  } 
  useEffect(() => {
    const fetchclothes = async () => {
      const document = collection(fireStore, 'clothes');
      const q = query(document, orderBy('postedTime'));
      const unsubscribe = onSnapshot(q, (snapshot) => {
        let temp = [];
        snapshot.docs.forEach((doc) => {
          temp.push({ ...doc.data(), id: doc.id });
        });
        setclothes(temp);
        setisLoading(false);
      }, (error) => {
        console.error('Error fetching clothes:', error);
        setisLoading(false);
      });

      // Clean up the listener when the component unmounts
      return unsubscribe;
    };

    fetchclothes();
  }, []);
  useEffect(() => {
    // Initialize currentIndexes state with the first index for each cloth
    const initialIndexes = clothes.reduce((acc, cloth) => {
      acc[cloth.id] = 0;
      return acc;
    }, {});
    setCurrentIndexes(initialIndexes);
  }, [clothes]);
  const handlePrevious = (clothPhotos, id) => {
    setCurrentIndexes((prevIndexes) => ({
      ...prevIndexes,
      [id]: (prevIndexes[id] === 0 ? clothPhotos.length - 1 : prevIndexes[id] - 1),
    }));
  };
  const handleNext = (clothPhotos, id) => {
    setCurrentIndexes((prevIndexes) => ({
      ...prevIndexes,
      [id]: (prevIndexes[id] === clothPhotos.length - 1 ? 0 : prevIndexes[id] + 1),
    }));
  };
  function ClothGallery({ cloth, currentIndex, handlePrevious, handleNext }) {
    return (
      <div className=''>
         <div className='w-full'>
         <div className="relative w-full">
         {cloth.clothPhotos.length >= 2 && (
                 <button
                 type="button"
                 className="z-100 absolute top-0 start-0 z-30 flex items-center justify-center h-full px-2 cursor-pointer group focus:outline-none"
                 data-carousel-prev
               >
               <span onClick={() => handlePrevious(cloth.clothPhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
  
                   <svg
                     
                     className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                     aria-hidden="true"
                     xmlns="http://www.w3.org/2000/svg"
                     fill="none"
                     viewBox="0 0 6 10"
                   >
                     <path
                       stroke="currentColor"
                       stroke-linecap="round"
                       stroke-linejoin="round"
                       stroke-width="2"
                       d="M5 1 1 5l4 4"
                     />
                   </svg>
                   <span className="sr-only">Previous</span>
                 </span>
               </button>
           
            )}
  
            {cloth.clothPhotos.length >= 2 && (
               <div
               type="div"
               className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-2  group focus:outline-none"
               data-carousel-next
             >
               <span onClick={() => handleNext(cloth.clothPhotos)} className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 cursor-pointer hover:bg-white/50  focus:ring-4 focus:ring-white  group-focus:outline-none">
                 <svg
                   
                   className="w-4 h-4 text-gray-800 dark:text-gray-800 rtl:rotate-180"
                   aria-hidden="true"
                   xmlns="http://www.w3.org/2000/svg"
                   fill="none"
                   viewBox="0 0 6 10"
                 >
                   <path
                     stroke="currentColor"
                     stroke-linecap="round"
                     stroke-linejoin="round"
                     stroke-width="2"
                     d="m1 9 4-4-4-4"
                   />
                 </svg>
                 <span className="sr-only">Next</span>
               </span>
             </div>
  
            )}
            <Link to={`/cloth/${cloth.id}`}>
           {cloth && cloth.clothPhotos.map((photo, index) => (
          <div className={`productCard-card productCardroot-class-name1  ${index === currentIndex ? 'flex' : 'hidden'}`} style={{backgroundImage:`url(${photo})`,width:'100%'}}>
      <div className="productCard-info card-info">
        <span className="productCard-text1">{cloth.clothTitle} </span>
        <span className="productCard-text2">{cloth.clothPrice} ETB</span>
      </div>
    </div>
                ))}
</Link>
          </div>
        
        </div>
       
      </div>
      
    );
  }
  return (
    <div className="home-container10 w-full" >
      <Helmet>
        <title>Ephratawi Design</title>
        <meta property="og:title" content="Ephratawi Design" />
      </Helmet>
<Navigation navigationPop={navPop}/>
      {
        navPop === false &&
        <div style={{width:'100%'}}>
      <div className="hero-container section-container">
        <div className="home-max-width2 max-width">
          <div className="home-content1">
            <span className="home-subtitle beforeHeading" >
              Ephratawi Designs
            </span>
            <h1 
                   style={{color:'white',fontSize:'55px',marginBottom:'20px'}}       className="nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 text-white weight-medium uppercase"

            >
              <span>
              We offer the best deals on Habesha 
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <span className="home-text116">clothing</span>
            </h1>
            <span className="home-description">
            We offer affordable and high-quality Habesha clothing Our products are made from the finest materials and are designed with modern fashion in mind. We offer a variety of styles and colors to fit any taste and budget. 
            </span>
            <div className="home-container11">
            
              <Link to='/collection'>
              <button className="button button-transparent2 button ">Collection</button>
              </Link>

            </div>
          </div>
          <div className="home-image3">
            <img
              alt="image"
              src="https://firebasestorage.googleapis.com/v0/b/remon-82925.appspot.com/o/Untitled%20design%20(23).png?alt=media&token=b0a33510-6c87-4153-9a89-11ed4c79a15c"
              className="home-hero-image1"
            />
            
         
          </div>
        </div>
      </div>
      <div className="section-container">
        <div className="home-max-width3 max-width">
          <div className="home-image5">
            <img
              alt="image"
              src="https://ephratawi.s3.us-east-1.amazonaws.com/profile/ephratawi+(10).png"
              className="home-hero-image2"
            />
          </div>
          <div className="home-content2">
            <span className="home-text117 beforeHeading">how it works</span>
            <h1 className="home-text118">
              <span>
              
              Choose the cloth that best suits your needs from our collection
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
             
            </h1>
            <span className="home-text122">
            Browse our collection of cloths to find the right one for you. We have a wide range of fabrics, colors, and styles for any occasion. Shop now to find the perfect cloth for you. </span>
            <div className="home-container12">
              {/* <button className="button-secondary button bg-transparent">
                Learn more
              </button> */}
            </div>
          </div>
        </div>
      </div>
      <div className=" home-max-width6 max-width">
          <span className="home-text137 beforeHeading capitalize">Shop Now</span>
          <h1 className="home-text138">
            <span>Our Featured
            </span>
            <br></br>
            <span className='home-text120 capitalize'>Items</span>
          </h1>
         </div> 
      <div className=" clothGrid ">
            {clothes.map((cloth) => (
              <div key={cloth.id}>
                <ClothGallery
                  cloth={cloth}
                  currentIndex={currentIndexes[cloth.id] || 0}
                  handlePrevious={(clothPhotos) => handlePrevious(clothPhotos, cloth.id)}
                  handleNext={(clothPhotos) => handleNext(clothPhotos, cloth.id)}
                />
              </div>
            ))}
          </div>
    
      
   
      {/* <div className="home-section3 section-container">
        <div className="home-max-width5 max-width">
          <div className="home-image7">
            <img
              alt="image"
              src="/jc-gellidon-fnc3yctccoi-unsplash%2014%20%5B1%5D-1200w.png"
              className="home-hero-image4"
            />
          </div>
          <div className="home-content4">
            <span className="home-text129 beforeHeading">Get Started</span>
            <h1 className="home-text130">Requesting a quote</h1>
            <div className="home-step1">
              <div className="home-number1">
                <span className="home-text131">1</span>
              </div>
              <div className="home-container14">
                <span className="home-title2">Visit our quote page </span>
                <span className="home-text132">
                There, you can customize your quote based on your specific requirements.
                </span>
              </div>
            </div>
            <div className="home-step2">
              <div className="home-number2">
                <span className="home-text133">2</span>
              </div>
              <div className="home-container15">
                <span className="home-title3">Complete the form</span>
                <span className="home-text134">
                Type in the necessary information, such as the design, size, and quantity of the cloth. After filling out all the necessary information, click the "Submit" button.
                </span>
              </div>
            </div>
            <div className="home-step3">
              <div className="home-number3">
                <span className="home-text135">3</span>
              </div>
              <div className="home-container16">
                <span className="home-title4">
                Receive your personalized quote.
                </span>
                <span className="home-text136">
                Once submitted, our system will analyze the information and generate a quote tailored to your specific needs. You will receive the quote within a few seconds.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div className="section-container hero-container" style={{margin:'100px 0'}}>
        <div className="home-max-width6 max-width">
          <span className="home-text137 beforeHeading" style={{color:'white',marginTop:'40px'}}>Our Collection</span>
          <h1 className="home-text138">
            <span 
                   style={{color:'white',fontSize:'55px',marginBottom:'20px'}}       className="nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 text-white weight-medium uppercase"

            >Explore More</span>
            <br></br>
          </h1>
          <div className="home-cards-container">
            <Card text='Kids' rootClassName="cardroot-class-name"></Card>
            <Card text="Women" rootClassName="cardroot-class-name2"></Card>
          </div>
        </div>
      </div>
    
     <Footer/>
    </div>
}
    </div>
  )
}

export default Home
