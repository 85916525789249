import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { Link, useParams } from 'react-router-dom'
import { auth, fireStore,storage} from '../firebase';
import { collection, deleteDoc, doc, getDoc, onSnapshot,setDoc,addDoc, serverTimestamp, getDocs, Timestamp } from 'firebase/firestore';
import { getStorage,getDownloadURL,uploadBytesResumable,ref } from 'firebase/storage';
import { v4 } from 'uuid';
import Nav from './Navigation';
import NotFound from './not-found';
import Chapa from 'chapa';
import Aws from 'aws-sdk'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import PaymentComponent from './chapa';

export default function Checkout() {
    const [cart,setcart] =useState([])
    const [paymentStatus,setpaymentStatus] =useState({})

    const [uid,setuid] = useState('')
    const [user,setuser] =useState([])
    const [userDetail,setuserDetail] =useState()

    const [totalPrice,settotalPrice] =useState()
    const [pay,setpay] =useState(false)
    const [afterDiscount,setafterDiscount] =useState()
    const [successDiscount,setsuccessDiscount] =useState()
    const [navPop,setnavPop] =useState(false)
    const history = useHistory()


    const [discountCodePop,setdiscountCodePop] =useState(false)
    const [discountCode,setdiscountCode] =useState('')
    const [discountCodeError,setdiscountCodeError] =useState('')



    const [loading,setloading] =useState(false)
    const [isLoading,setisLoading] =useState(true)

    const [cbebirr,setpaymentMethod] =useState(false)
    const {uniqueId,status} = useParams()
    const [paymentMethods,setpaymentMethods] =useState(false)
    const [chosenMethod,setchosenMethod] =useState(false)

    const [paymentannounce,setpaymentannounce] =useState(true)
    const [sendPaymentDetails,setsendPaymentDetails] =useState(true)
    const [error,setError] =useState('')
    const [accountNumber,setaccountNumber] =useState('')

    const [success,setsuccess] =useState(false)
    const [progress,setprogress] =useState(0)


    const [screenShot,setscreenShot] =useState([])
    const [screenshotError,setscreenshoterror] =useState('')
    const [accountHolder,setaccountHolder] =useState('')
    const [accountHolderError,setaccountHolderError] =useState('')
    const [deliveryDateError,setdeliveryDateError] =useState('')  
   
    const [deliveryDate,setdeliveryDate] =useState('')
    const [accountNumberError,setaccountNumberError] =useState('')
    const [uploadMessage,setuploadMessage] =useState('')
    const [contactNumber,setcontactNumber] =useState('')
    const [contactNumberError,setcontactNumberError] =useState('')







    const [itemQuantities, setItemQuantities] = useState({});

    const handleIncrement = (itemId) => {
        setItemQuantities((prevQuantities) => ({
          ...prevQuantities,
          [itemId]: (prevQuantities[itemId] || 1) + 1,
        }));
      };

      function handleImgSelect(event) {
        const selectedFile = event.target.files[0]; // Access the first selected file directly
      
        // Check if a file is selected
        if (selectedFile) {
          // Check file type
          if (!selectedFile.type.startsWith('image/')) {
            setuploadMessage(`File ${selectedFile.name} is not a valid image file.`);
            setscreenshoterror('Invalid file type. Please select an image file.');
            setscreenShot([]);  // Clear the previous file selection
            return;  // Stop further execution
          }
      
          // Check file size
          const maxSize = 10 * 1024 * 1024; // 10MB
          if (selectedFile.size > maxSize) {
            setuploadMessage(`File ${selectedFile.name} exceeds the size limit of 10MB.`);
            setscreenshoterror('File is too large. Maximum size is 10MB.');
            setscreenShot([]);  // Clear the previous file selection
            return;  // Stop further execution
          }
      
          // Update the state with the selected file (single file)
          setscreenShot([selectedFile]);
          setuploadMessage('');  // Clear upload message
          setscreenshoterror('');  // Clear error message
        } else {
          // If no file is selected, reset state
          setuploadMessage('');
          setscreenShot([]);  // Clear the selected file
        }
      
        // Reset the input value to allow the same file to be selected again if needed
        event.target.value = '';
      }
      
      
      

      useEffect(() => {
          
        fetch(`https://mellifluous-khapse-6e5af3.netlify.app/.netlify/functions/api/verify/${uniqueId}`)
          .then(response => response.json())
          .then(result => setpaymentStatus(result))
          .catch(error => console.log('error', error));
      }, [uniqueId]);
   useEffect(() => {
  const updateOrderStatus = async () => {
    
    if (uid && paymentStatus?.data?.status === 'success') {
      try {
        // Ensure Firestore instance and document path are valid
        const userOrderDoc = doc(fireStore, 'TotalUsers', uid, 'order', uniqueId);
        const totalOrders = doc(fireStore, 'Orders', uniqueId);

        // Fetch document data for the order
        const docSnapshot = await getDoc(totalOrders);

        console.log('Document Data:', docSnapshot.exists() ? docSnapshot.data() : 'Document does not exist');

        // Only set the document if it doesn't exist
        if (docSnapshot.exists() && !docSnapshot.data().paymentAcceptedTime) {
          await setDoc(userOrderDoc, { status: 'accepted', paymentAcceptedTime: serverTimestamp() }, { merge: true });
          await setDoc(totalOrders, {
            status: 'accepted',
            paymentAcceptedTime: serverTimestamp(),
          }, { merge: true });

          console.log('Order status updated to completed');
        }
      } catch (error) {
        // Log and handle errors
        console.error('Error updating order:', error);
      }
    }
    if (uid && paymentStatus?.data?.status.includes('failed')) {
      try {
        // Ensure Firestore instance and document path are valid
        const userOrderDoc = doc(fireStore, 'TotalUsers', uid, 'order', uniqueId);
        const totalOrders = doc(fireStore, 'Orders', uniqueId);

        // Fetch document data for the order
        const docSnapshot = await getDoc(totalOrders);

        console.log('Document Data:', docSnapshot.exists() ? docSnapshot.data() : 'Document does not exist');

        // Only set the document if it doesn't exist
        if (docSnapshot.exists() && !docSnapshot.data().paymentDeclinedTime) {
          await setDoc(userOrderDoc, { status: 'declined', paymentDeclinedTime: serverTimestamp() }, { merge: true });
          await setDoc(totalOrders, {
            status: 'declined',
            paymentDeclinedTime: serverTimestamp(),
          }, { merge: true });

          console.log('Order status updated to completed');
        }
      } catch (error) {
        // Log and handle errors
        console.error('Error updating order:', error);
      }
    }
  };

  updateOrderStatus();
}, [paymentStatus, uid, uniqueId]);



      const customerInfo = {
        amount: '100',
        currency: 'ETB',
        email: 'abebe@bikila.com',
        first_name: 'Abebe',
        last_name: 'Bikila',
        callback_url: 'https://your-callback-url.com', // Your callback URL
        customization: {
          title: 'I love e-commerce',
          description: 'It is time to pay',
        },
      };
    
      const handlePayment = async () => {
        setloading(true);
        setError(null);
    
        try {
          const response = await fetch('https://mellifluous-khapse-6e5af3.netlify.app/.netlify/functions/api/verify/api/payment', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              mode:'no-cors'
            },
            body: JSON.stringify(customerInfo),
          });
    
          const data = await response.json();
          if (paymentStatus?.data?.status === 'success') {
            const checkoutUrl = data.data.checkout_url;
            const txRef = data.tx_ref;
    
            console.log('Checkout URL:', checkoutUrl);
            history.push(checkoutUrl);  // Redirect to Chapa checkout page
          } else {
            setError('Payment initialization failed');
          }
        } catch (e) {
          console.error('Error during payment initialization:', e);
          setError('An error occurred during payment initialization.');
        } finally {
          setloading(false);
        }
      };
    
      
      Aws.config.update({
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID, // Store this in environment variable
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY, // Store this in environment variable
        region: 'us-east-1', // Your S3 bucket region
      })
    
    
    function discountCodeSet(e){
        setdiscountCode(e.target.value)
        if(e.target.value.length ===0){
          setdiscountCodeError('error')
        }
        else{
          setdiscountCodeError()
        }

      }
      function discountCodePopSet(){
        if(discountCodePop === true){
          setdiscountCodePop(false)
          setdiscountCodeError('')
          setdiscountCode('')
        }
        else{
          setdiscountCodePop(true)
          setdiscountCodeError('')
          setdiscountCode('')
        }
      }

      function accountHolderSet(e){
        setaccountHolder(e.target.value)

        if(e.target.value.length ===0){
            setaccountHolderError('error')
        }
        else{
            setaccountHolderError('')

        }
      }
      function convertToFireStoreTimestamp(dateString) {
        const [year, month, day] = dateString.split('-').map(Number);
        const timestamp = new (Timestamp)(
          new Date(year, month - 1, day).getTime() / 1000,
          0
        );
        return timestamp;
      }
      function deliveryDateSet(e) {
        const dateString = e.target.value;
        const today = new Date();
        
        // Calculate the valid date range: 2 days from today to 2 months from today
        const minDate = new Date();
        minDate.setDate(today.getDate() + 2); // Minimum date is 2 days from today
        
        const maxDate = new Date();
        maxDate.setMonth(today.getMonth() + 2); // Maximum date is 2 months from today
    
        // Check if the dateString is in the expected format (YYYY-MM-DD)
        if (dateString.match(/^\d{4}-\d{2}-\d{2}$/)) {
            // Convert the dateString to a Date object
            const selectedDate = new Date(dateString);
            
            // Check if the selected date is within the valid range
            if (selectedDate >= minDate && selectedDate <= maxDate) {
                // The dateString is valid and within range,
                // so convert it to a Firestore Timestamp
                setdeliveryDate(convertToFireStoreTimestamp(dateString));
                setdeliveryDateError('');
            } else if (selectedDate < minDate) {
                setdeliveryDate(null);
                setdeliveryDateError('Please select a date at least 2 days from today.');
            } else {
                setdeliveryDate(null);
                setdeliveryDateError('Please select a date within the next two months.');
            }
        } else {
            setdeliveryDate(null);
            setdeliveryDateError('Please enter a valid date in the format YYYY-MM-DD.');
        }
    }
    
      function accountNumberSet(e){
        setaccountNumber(e.target.value)
        if(e.target.value.length ===0){
            setaccountNumberError('error')
        }
        else{
            setaccountNumberError('')

        }
      }
      function contactNumberSet(e){
        setcontactNumber(e.target.value)
        if(e.target.value.length ===0){
            setcontactNumberError('error')
        }
        else{
            setcontactNumberError('')

        }
      }

      function payPop(){
        setpay(true)

      }
      function payClose(){
        setpay(false)
      }
      function goToPayment(){
        setpaymentannounce(false)

    setsendPaymentDetails(true)
            
     
    
      }

      function goToSendPaymentDetails(){
            setpaymentMethod(false)
    
        setsendPaymentDetails(true)
                
        
      }

        function closesuccessDiscount(){
          setsuccessDiscount(false)
        }
      function applyDiscountCode(){
        if(discountCode.length ===0){
          setdiscountCodeError('Invalid Discount Code')
  
         }
         else if (discountCode === 'tangerine'){
          setafterDiscount((totalPrice * 85)/100)
          setsuccessDiscount(true)
         }
         else{
          setdiscountCodeError('Invalid Discount Code')
         }

      }
      function  backSendPaymentDetails(){
        setaccountHolder('')
        setaccountNumber('')
        setscreenShot('')
        setscreenshoterror('')
        setaccountHolderError('')
        setaccountNumberError('')
            setsendPaymentDetails(false)
    
        setpaymentannounce(true)
                
          
      }
      async function uploadScreenshot() {
        try {
          // Step 1: Validation checks
          if (accountHolder.length === 0) {
            setaccountHolderError('error');
            return;
          } else {
            setaccountHolderError('');
          }
      
          if (accountNumber.length === 0) {
            setaccountNumberError('error');
            return;
          } else {
            setaccountNumberError('');
          }
      
          if (screenShot.length === 0) {
            setscreenshoterror('error');
            return;
          } else {
            setscreenshoterror('');
          }
      
          if (contactNumber.length === 0) {
            setcontactNumberError('error');
            return;
          } else {
            setcontactNumberError('');
          }
      
          if (!deliveryDate) {
            setdeliveryDateError('Please enter a valid date in the format YYYY-MM-DD.');
            return;
          } else {
            setdeliveryDateError('');
          }
      
          // Step 2: Initialize upload process
          setloading(true);
          setprogress(0);
      
          // Check if files are selected
          if (screenShot.length === 0) {
            alert('Please select at least one image to upload.');
            return;
          }
      
          const s3 = new Aws.S3();
          const uploadedImageUrls = [];
      
          // Firebase Firestore document references
          const cartDoc = collection(fireStore, 'TotalUsers', uid, 'Cart');
          const orderDoc = doc(fireStore, 'Orders', uniqueId);
          const userOrderDoc = doc(fireStore, 'TotalUsers', uid, 'Orders', uniqueId);
      
          // Step 3: Upload screenshots to S3
          for (let i = 0; i < screenShot.length; i++) {
            const selectedFile = screenShot[i];
            const params = {
              Bucket: 'rimmon',  // Replace with your bucket name
              Key: `orders/${selectedFile.name}`,  // Path in the bucket
              Body: selectedFile,
              ContentType: selectedFile.type,
            };
      
            // Perform the upload and track progress
            const upload = s3.upload(params);
      
            upload.on('httpUploadProgress', (progress) => {
              const progressPercentage = Math.round((progress.loaded / progress.total) * 100);
              console.log(`Progress for ${selectedFile.name}: ${progressPercentage}%`);
              setprogress(progressPercentage);  // Update global progress or handle separately
            });
      
            // Wait for file upload to complete
            await new Promise((resolve, reject) => {
              upload.send((err, data) => {
                if (err) {
                  console.log(`Error uploading file ${selectedFile.name}:`, err);
                  alert(`Error uploading ${selectedFile.name}`);
                  reject(err);
                } else {
                  console.log(`File ${selectedFile.name} uploaded successfully:`, data);
                  uploadedImageUrls.push(data.Location);  // Store the uploaded file URL
                  resolve();
                }
              });
            });
          }
      
          // Step 4: Update Firestore with transaction details
          const transaction = {
            clothes: cart,
            accountHolder,
            accountNumber,
            screenshot: uploadedImageUrls,
            transactionId: uniqueId,
            totalAmount: afterDiscount || totalPrice,
            deliveryDate,
            time: serverTimestamp(),
            userId: uid,
            status: 'pending',
            phoneNumber: contactNumber,
          };
      
          // Add transaction to orders collection
          await setDoc(orderDoc, transaction);
          await setDoc(userOrderDoc, transaction).then(
            setloading(false),  // Hide the loading indicator
            setsuccess(true),   // Set success state to true
          )
      
          // Step 5: Remove items from the cart
          const cartSnapshot =  getDocs(cartDoc);
          for (const documentSnapshot of cartSnapshot.docs) {
             deleteDoc(doc(fireStore, 'TotalUsers', uid, 'Cart', documentSnapshot.id));
          }
      
          // Step 6: Final state updates (after all operations complete)
       // Set progress to 100%
      
        } catch (error) {
          console.error('An error occurred during upload:', error);
          setloading(false);  // Hide the loading indicator
          setError((prevErrors) => ({
            ...prevErrors,
            general: 'An error occurred while adding the cloth. Please try again.',
          }));
        }
      }
      
      
      


      function backToAnnounce(){
            setpaymentannounce(true)
    
                
      
      }
      function backCbeBirr(){
        setpaymentMethod(false)
            
    
        setpaymentannounce(true)
                
    
      }
      
      const handleDecrement = (itemId) => {
        setItemQuantities((prevQuantities) => ({
          ...prevQuantities,
          [itemId]: Math.max((prevQuantities[itemId] || 1) - 1, 1),
        }));
      };

    useEffect(() => {

        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
        
            const uid = user.uid;
            setuid(uid)

            onSnapshot(doc(fireStore,'TotalUsers',uid),snapshot=>{
                setuser([{...snapshot.data(),id:snapshot.id}])
                setuserDetail(snapshot.data())

            })
          
      
      
                
           
                
              
      
              
          
      
                 
      
                 
                 
      
                    
          
      
            
      
           
            
            // ...
          } else {
            history.push('/')

            
          }
        });
      
        
          
      
        return () => {
          unsubscribe();
        };
      }, []);

      useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
          if (user) {
            const uid = user.uid;
            setuid(uid);
      
            const unsubscribeCart = onSnapshot(
              collection(fireStore, 'TotalUsers', uid, 'Cart'),
              async (snapshot) => {
                console.log(snapshot.empty);
            
                if (snapshot.empty) {
                  setcart([]);  // Reset the cart if it's empty
                  setisLoading(false);
                } else {
                  try {
                    // Use map with async/await properly
                    const items = await Promise.all(
                      snapshot.docs.map(async (snapDoc) => {
                        // Fetch the cloth data
                        const cakeDoc = await getDoc(doc(fireStore, 'clothes', snapDoc.data().clothId));
                        const quantity = itemQuantities[snapDoc.id] || 1;  // Use updated quantities
                        return { 
                          ...snapDoc.data(), 
                          id: snapDoc.id, 
                          clothData: cakeDoc.data(), 
                          quantity 
                        };
                      })
                    );
            
                    // Update the cart state with the fetched data
                    setcart(items);
                  } catch (error) {
                    console.error('Error fetching cart data:', error);
                  } finally {
                    // Always stop loading when done, even on error
                    setisLoading(false);
                  }
                }
              }
            );
            
      
            return () => {
              unsubscribeCart();
              unsubscribe();
            };
          } else {
            console.log("User logged out");
          }
        });
      
        // Ensure itemQuantities is updated as a dependency
      }, [itemQuantities]);
      
      
      useEffect(() => {
        const newTotalPrice = cart.reduce((total, i) => {
          const itemQuantity = itemQuantities[i.id] || 1;
          console.log(i.clothData)
          const itemPrice = roundToTwoDecimalPlaces(i.clothData && i.clothData.clothPrice) * roundToTwoDecimalPlaces(itemQuantity);
          return total + itemPrice;
        }, 0);
        settotalPrice(roundToTwoDecimalPlaces(newTotalPrice));
        
      }, [cart, itemQuantities]);
function handlePaymentMethod(method) {
  // Set the chosen method immediately
  setchosenMethod(method);
    setpaymentMethod(false)      
    setpaymentMethod(true); 

}

function roundToTwoDecimalPlaces(num) {
  if(num){
    return Math.round(num * 100) / 100;
  }
}

function successClose(){
    setsuccess(false)
    setsendPaymentDetails(false)
    setpay(false)
    setpaymentannounce(true)
}
      function handleRemoveCart(id){
        setisLoading(true)
        deleteDoc(doc(fireStore,'TotalUsers',uid,'Cart',id)).then(
        )
      }
      function formatNumber(num) {
        if(num){
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        };
      }

  return (
  user.map(i=>{
    const {fullName,email,phoneNumber} = i
    return(
      isLoading === true ?
         <div className='signInpop ' style={{zIndex:1001}}>
        <div style={{marginTop:'20px'}} class="bg-white max-w-md mx-auto flex items-center justify-center shadow border rounded  w-full p-10 ">
   
   <div
   className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
   role="status"></div></div></div>
      :
       uniqueId.length >= 40 && uniqueId.length <= 105 ?
        <div>
        <div id='desktop'>
           {
            paymentStatus?.data?.status === 'success'?
                       <div class="h-full   w-full  px-4 pb-10 paymentPop" style={{zIndex:'10000'}}>
 <div style={{width:'70vh',height:'90vh'}} class="flex-col bg-white shadow border rounded  w-full p-10 py-30 flex justify-center items-center">

<svg xmlns="http://www.w3.org000/svg" width="80" height="80" fill="currentColor" className="bi bi-check-circle-fill text-green-500" viewBox="0 0 16 16">
<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<h1 className='nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 color-var(--podium-cds-color-text-primary) weight-medium uppercase mt-3 text-center' style={{fontSize:'28px'}}>Thank you for placing your order</h1>
<p className='par text-gray-600 text-center mt-2' style={{fontSize:'12px'}}>Once the receipt has been verified, our representative will contact you to confirm the order.</p>

<Link to={`/orders/${uniqueId}`}>
<button

             type='button'
             
             style={{fontSize:'12px',background:'black',height:'55px',width:'100%'}}
             className='flex justify-center items-center par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
           >
            Track Order
           </button></Link>
            
            
              </div> 
             
            </div>
            :
                paymentStatus?.data?.status.includes('failed/')?
                <div class="h-full   w-full  px-4 pb-10 paymentPop" style={{zIndex:'10000'}}>
<div style={{width:'70vh',height:'90vh'}} class="flex-col bg-white shadow border rounded  w-full p-10 py-30 flex justify-center items-center">

<div className=' p-5 '>
<img width="70" height="70" src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/close.png?alt=media&token=44ab7ef9-da9d-4a0a-838f-881504dcafc3'/>

</div>
<h1 className='nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 color-var(--podium-cds-color-text-primary) weight-medium uppercase mt-3 text-center' style={{fontSize:'28px'}}>Payment Failed</h1>
<p className='par text-gray-600 text-center mt-2' style={{fontSize:'12px'}}>We've encountered an error with the transaction, either it's been tried before and failed or there's an internal error. Please try again later </p>

<Link to={`/collection/${uid + v4().slice(0,30)}/checkout`}>
<button

      type='button'
      
      style={{fontSize:'12px',background:'black',height:'55px',width:'100%'}}
      className='flex justify-center items-center par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
    >
     Retry
    </button></Link>
     
     
       </div> 
      
     </div>
              :
            pay=== true &&
            <div class="h-full   w-full  px-4 pb-10 paymentPop" style={{zIndex:'10000'}}>

            <div class="flex flex-col items-center justify-center">
                {
                 
                    loading === true ?
                <div style={{width:'70vh',height:'90vh'}} class="bg-white flex-col shadow border rounded  w-full p-10 py-30 flex justify-center items-center">
                    <div
                      className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
                      role="status"></div>
                      {
                        progress !=0 && progress !== 100 &&
                        <div className=" bg-gray-200 rounded-full  mt-5">
                        <div className=" text-xs font-medium text-gray-800 text-center p-0.5 leading-none rounded-full" style={{width:progress,background:'#31ffaa'}}> {progress}%</div>
                      </div>
                      }
                  
                    </div>
                  
                    
                    :
                     paymentannounce === true ?
                    <div style={{width:'70vh',height:'90vh'}} class="bg-white shadow border rounded  w-full p-10 py-30 ">
                    <svg onClick={payClose} xmlns="http://www.w3.org000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-chevron-left" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
    </svg>
                    <p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800 mb-8 mt-14">Make the payment</p>
                   
                    
         
         
    
                    <div className="relative mx-auto mt-12" style={{width:'80%'}}>
    
                    <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}>Cloth price</dt>
                <dd class="text-base font-medium text-gray-900 ">{formatNumber(totalPrice)} ETB</dd>
              </dl>
          
              {
              afterDiscount &&
              <dl class="flex items-center justify-between gap-4">
              <dt class="text-base font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}>After Discount</dt>
            <div className="flex">
            <dd class="text-base font-medium text-gray-900 line">{formatNumber(afterDiscount)} ETB</dd>
            </div>              
            </dl>
            }
    
              
           
              <dl class="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 ">
              <dt class="text-base font-bold text-gray-900 ">Total Now</dt>
              <dd class="text-base font-bold text-gray-900 ">{formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : totalPrice)))} ETB</dd>
            </dl>
            <p className='ml-2 text-gray-600 par mt-5 mb-8 ' style={{fontSize:'13px'}}>
            Please proceed with completing the payment through our secure payment gateway for a safe and efficient transaction.</p>
    
    </div>
    <button
                   type='button'
                   onClick={goToPayment}
                   style={{fontSize:'12px',background:'black',height:'55px',width:'100%'}} disabled={loading}
                   className='flex justify-center items-center par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                   >
                  I understand
                 </button>
                       
                    </div>
                
                   
                     :
                     sendPaymentDetails === true &&
                     <div style={{width:'70vh',height:'90vh',overflowY:'scroll'}} class="bg-white shadow border rounded  w-full p-10 py-30 ">
                     <svg onClick={backSendPaymentDetails} xmlns="http://www.w3.org000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-chevron-left" viewBox="0 0 16 16">
       <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
     </svg>
                     <p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800 mb-8 mt-14">Make the payment</p>
                    
                     
          
          

 <div className="relative mx-auto my-10" style={{width:'80%'}}>
 <p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Pick Up Date</p>
 
 
   <input onChange={(e)=>deliveryDateSet(e)} style={{fontSize:'12px',height:'60px'}} datepicker type="date" class="w-full border  focus:outline-none
 focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
 border-gray-300 rounded-md capitaliz" placeholder="Select date"/>
 <div className={`${deliveryDateError.length !== 0 ? "block":"hidden"}`}>
 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org000/svg" fill="currentColor" viewBox="0 0 20 20">
 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
 </svg>
 <span className="sr-only">Info</span>
 <div className='Mooli'>
 {deliveryDateError}
 </div>
 </div>
 </div>
 </div>

 
{
  deliveryDate &&
  <PaymentComponent
  phoneNumber={phoneNumber}
  email={email}
  fullName={fullName}
  uniqueId={uniqueId}
  afterDiscount={afterDiscount}
  deliveryDate={deliveryDate}
  totalPrice={totalPrice}
  contactNumber={contactNumber}
  
  uid={uid}

  cart={cart}

  />
}
                        
                     </div> 

            
                 
                    
                    
            
                }
            </div>
        </div>
           }
      
            <Nav navigationPop={navPop} component={'Checkout'}/>
    
            <section class=" antialiased  md:py-16" style={{marginTop:'10px'}}>
            <div className={`${discountCodeError ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
{discountCodeError}
</div>
</div>
            </div>
          <div className={`${successDiscount ? "block":"hidden"}`}>
          <div className="flex items-center w-full p-4 mb-4 text-sm text-green-800 border border-green-300 rounded-lg bg-green-50 " role="alert">
          <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org000/svg" fill="currentColor" viewBox="0 0 20 20">
          <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
          </svg>
          <span className="sr-only">Info</span>
          <div className='Mooli'>
          Discount code applied successfully
          </div>
          <p className='ml-10 cursor-pointer ' onClick={closesuccessDiscount}>x</p>
          </div>
          </div>
  <div class="mx-auto max-w-screen-xl px-4 2xl:px-0">
{
  cart.length !=0 &&
  <h2 class="text-xl font-semibold text-gray-900  sm:text-2xl barlow mb-4">Shopping Cart</h2>

}
    <div class="  md: lg:flex lg:items-start xl:gap-8">
      <div class="mx-auto w-full flex-none lg:max-w-2xl xl:max-w-4xl">
        <div class="space-y-6">
            {
                cart.length === 0 &&
                <div className='flex items-center justify-center flex-col  '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/Add%20to%20Cart-amico.svg?alt=media&token=09878efb-c968-4931-a73d-7e721403588e" alt="Empty Cart" style={{width:"40%"}}/>
  <h1 className='fjalla text-center text-gray-900 mt-8 par' style={{fontSize:"20px"}}>Your Cart is Empty
  </h1>

<Link to={'/collection'}>
<button
                   type='button'
                   style={{fontSize:'14px',height:'53px',display:'flex',alignItems:'cente'}}
                   className='par flex items-center justify-center mt-12  w-full text-white  bg-gray-950 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                 >
                   Continue Shopping
                 </button></Link>

</div>
            }
            {
                cart.map(i=>{
                    console.log(i.clothData)
                    return(
                        <div class="rounded-lg border border-gray-200 bg-white p-4 shadow-sm  md:p-6 " style={{width:'100%'}}>
                        <div class="flex items-center w-full ">
                        {i.clothData && i.clothData.clothPhotos && (
  <img
  style={{width:'120px',height:'120px'}}
  class=" rounded mr-5"
  src={i.clothData && i.clothData.clothPhotos[0]}

    alt="cloth Image"
  />
)}
            
                          <label for="counter-input" class="sr-only">Choose quantity:</label>
                          <div class="flex items-center justify-between md:order-3 md:justify-end">
                            <div class="flex items-center">
                              <button onClick={ ()=>handleDecrement(i.id)}type="button" id="decrement-button" data-input-counter-decrement="counter-input" class="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 ">
                                <svg class="h-2.5 w-2.5 text-gray-900 " aria-hidden="true" xmlns="http://www.w3.org000/svg" fill="none" viewBox="0 0 18 2">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16" />
                                </svg>
                              </button>
                              <input type="text" id="counter-input" data-input-counter class="w-10 shrink-0 border-0 bg-transparent text-center text-sm font-medium text-gray-900 focus:outline-none focus:ring-0 " placeholder="" value={itemQuantities[i.id] || 1} required />
                              <button onClick={ ()=>handleIncrement(i.id)} type="button" id="increment-button" data-input-counter-increment="counter-input" class="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 ">
                                <svg class="h-2.5 w-2.5 text-gray-900 " aria-hidden="true" xmlns="http://www.w3.org000/svg" fill="none" viewBox="0 0 18 18">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                                </svg>
                              </button>
                            </div>
                            <div class="text-end md:order-4 md:w-32">
                              <p class="text-base font-bold text-gray-900 ">{roundToTwoDecimalPlaces(i.clothData && i.clothData.clothPrice * (itemQuantities[i.id] || 1))} ETB</p>
                            </div>
                          </div>
            
                          <div class="w-full min-w-0 flex-1 space-y-4 md:order-2 md:max-w-md">
                            <a href="#" class="text-base font-medium text-gray-900 hover:underline  header"> {i.clothData && i.clothData.clothTitle}</a>
            
                            <div class="flex items-center gap-4">
                           
            
                              <button onClick={()=>handleRemoveCart(i.id)} type="button" class="inline-flex items-center text-sm font-medium text-red-600 hover:underline ">
                                <svg class="me-1.5 h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 17.94 6M18 18 6.06 6" />
                                </svg>
                                Remove
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                })
            }
        
    
       
   
      
        </div>
      
      </div>

      <div class="mx-auto mt-6 max-w-4xl flex-1 space-y-6 lg:mt-0 lg:w-full ml-6">
        <div class="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm  sm:p-6">
          <p class="text-xl font-semibold text-gray-900 ">Order summary</p>

          <div class="space-y-4">
            <div class="space-y-2">
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}>Cloth Price</dt>
                <dd class="text-base font-medium text-gray-900 ">{formatNumber(totalPrice) ? formatNumber(totalPrice) : 0} ETB</dd>
              </dl>
            {
              afterDiscount &&
              <dl class="flex items-center justify-between gap-4">
              <dt class="text-base font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}>After Discount</dt>
            <div className="flex">
            <dd class="text-base font-medium text-gray-900 line">{formatNumber(afterDiscount)} ETB</dd>
            </div>              
            </dl>
            }

              {/* <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}>Prepayment (ቅድመ ክፍያ)</dt>
                <dd class="text-base font-medium text-green-600">{formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : afterDiscount ? afterDiscount : totalPrice))) ? formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : afterDiscount ? afterDiscount : totalPrice))) : 0} ETB</dd>
              </dl> */}

              
              {/* <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}>After payment (ድህረ ክፍያ)</dt>
                <dd class="text-base font-medium text-green-600">{formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : totalPrice)))? formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : totalPrice))): 0} ETB</dd>
              </dl> */}

              


            </div>
          {
            afterDiscount &&
            <dl class="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 ">
            <dt class="text-base font-bold text-gray-900 ">Discount</dt>
            <dd class="text-base font-bold text-gray-900 ">15% Off</dd>
          </dl>
          }
            <dl class="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 ">
              <dt class="text-base font-bold text-gray-900 ">Total Now</dt>
              <dd class="text-base font-bold text-gray-900 ">{formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : totalPrice)))? formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : totalPrice))):0} ETB</dd>
            </dl>
          </div>

{
  cart.length === 0 ?
            <a  style={{color:'white'}} class="text-white disabled flex w-full items-center justify-center rounded-lg bg-blue-800 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-primary-300  ">Proceed to Checkout</a>
:
          <a onClick={payPop} style={{fontSize:'12px',height:'55px'}}class="flex w-full items-center justify-center rounded-lg bg-blue-800 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-primary-300  ">Proceed to Checkout</a>
}
{
  discountCodePop === true ?
  <div className='flex justify-between w-full items-center'>
<input onChange={(e)=>discountCodeSet(e)} style={{fontSize:'12px',height:'50px'}}   required  autoComplete="off" placeholder="Discount Code" type="text" className="  focus:outline-none  w-full pt-5 pr-4 par pb-5 pl-4  mr-0 mb-0 ml-0 text-gray-700 block bg-white border-gray-300 rounded-md input-field-signUp" />
<a onClick={applyDiscountCode} style={{fontSize:'12px',height:'50px',width:'30%',color:'black',backgroundColor:'#31ffaa'}}class="ml-3 flex w-full items-center justify-center rounded-lg bg-blue-800 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-primary-300  ">Apply</a>

<p onClick={discountCodePopSet} className='ml-3 cursor-pointer'>x</p>
  </div>
:
cart.length === 0 ?
<a    style={{fontSize:'12px',height:'55px',backgroundColor:'#31ffaa',color:'black'}}class="disabled flex w-full items-center justify-center rounded-lg bg-blue-800 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-primary-300  border">Discount Code?</a>
:
<a   onClick={discountCodePopSet} style={{fontSize:'12px',height:'55px',backgroundColor:'#31ffaa',color:'black'}}class="flex w-full items-center justify-center rounded-lg bg-blue-800 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-primary-300  border">Discount Code?</a>

}
<div className={`${discountCodeError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Discount Code can't be empty
</div>
</div>
</div>

          <div class="flex items-center justify-center gap-2">
            <span class="text-sm font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}> or </span>
       <Link to={'/collection'}>
       <a title="" class="inline-flex items-center gap-2 text-sm font-medium text-primary-700 underline hover:no-underline dark:text-primary-500">
              Continue Shopping
              <svg class="h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org000/svg" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4" />
              </svg>
            </a></Link>
          </div>
        </div>

      </div>
    </div>
  </div>
            </section>

        </div>
        <div id='tablet'>
        {
            paymentStatus?.data?.status === 'success'?
                       <div class="h-full   w-full  px-4 pb-10 paymentPop" style={{zIndex:'10000'}}>
 <div style={{width:'70vh',height:'90vh'}} class="flex-col bg-white shadow border rounded  w-full p-10 py-30 flex justify-center items-center">

<svg xmlns="http://www.w3.org000/svg" width="80" height="80" fill="currentColor" className="bi bi-check-circle-fill text-green-500" viewBox="0 0 16 16">
<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<h1 className='nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 color-var(--podium-cds-color-text-primary) weight-medium uppercase mt-3 text-center' style={{fontSize:'28px'}}>Thank you for placing your order</h1>
<p className='par text-gray-600 text-center mt-2' style={{fontSize:'12px'}}>Once the receipt has been verified, our representative will contact you to confirm the order.</p>

<Link to={`/orders/${uniqueId}`}>
<button

             type='button'
             
             style={{fontSize:'12px',background:'black',height:'55px',width:'100%'}}
             className='flex justify-center items-center par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
           >
            Track Order
           </button></Link>
            
            
              </div> 
             
            </div>
            :
            paymentStatus?.data?.status.includes('pending')?
            <div class="h-full   w-full  px-4 pb-10 paymentPop" style={{zIndex:'10000'}}>
<div style={{width:'70vh',height:'90vh'}} class="flex-col bg-white shadow border rounded  w-full p-10 py-30 flex justify-center items-center">

<div className=' p-5 '>
<img width="70" height="70" src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/close.png?alt=media&token=44ab7ef9-da9d-4a0a-838f-881504dcafc3'/>

</div>
<h1 className='nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 color-var(--podium-cds-color-text-primary) weight-medium uppercase mt-3 text-center' style={{fontSize:'28px'}}>Payment Failed</h1>
<p className='par text-gray-600 text-center mt-2' style={{fontSize:'12px'}}>We've encountered an error with the transaction, either it's been tried before and failed or there's an internal error. Please try again later </p>

<Link to={`/collection/${uid + v4().slice(0,30)}/checkout`}>
<button

  type='button'
  
  style={{fontSize:'12px',background:'black',height:'55px',width:'100%'}}
  className='flex justify-center items-center par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
>
 Retry
</button></Link>
 
 
   </div> 
  
 </div>
 :
                paymentStatus?.data?.status.includes('failed/')?
                <div class="h-full   w-full  px-4 pb-10 paymentPop" style={{zIndex:'10000'}}>
<div style={{width:'70vh',height:'90vh'}} class="flex-col bg-white shadow border rounded  w-full p-10 py-30 flex justify-center items-center">

<div className=' p-5 '>
<img width="70" height="70" src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/close.png?alt=media&token=44ab7ef9-da9d-4a0a-838f-881504dcafc3'/>

</div>
<h1 className='nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 color-var(--podium-cds-color-text-primary) weight-medium uppercase mt-3 text-center' style={{fontSize:'28px'}}>Payment Failed</h1>
<p className='par text-gray-600 text-center mt-2' style={{fontSize:'12px'}}>We've encountered an error with the transaction, either it's been tried before and failed or there's an internal error. Please try again later </p>

<Link to={`/collection/${uid + v4().slice(0,30)}/checkout`}>
<button

      type='button'
      
      style={{fontSize:'12px',background:'black',height:'55px',width:'100%'}}
      className='flex justify-center items-center par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
    >
     Retry
    </button></Link>
     
     
       </div> 
      
     </div>
              :
            pay=== true &&
            <div class="h-full   w-full  px-4 pb-10 paymentPop" style={{zIndex:'10000'}}>

            <div class="flex flex-col items-center justify-center">
                {
                 
                    loading === true ?
                <div style={{width:'70vh',height:'90vh'}} class="bg-white flex-col shadow border rounded  w-full p-10 py-30 flex justify-center items-center">
                    <div
                      className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
                      role="status"></div>
                      {
                        progress !=0 && progress !== 100 &&
                        <div className=" bg-gray-200 rounded-full  mt-5">
                        <div className=" text-xs font-medium text-gray-800 text-center p-0.5 leading-none rounded-full" style={{width:progress,background:'#31ffaa'}}> {progress}%</div>
                      </div>
                      }
                  
                    </div>
                  
                    
                    :
                     paymentannounce === true ?
                    <div style={{width:'70vh',height:'90vh'}} class="bg-white shadow border rounded  w-full p-10 py-30 ">
                    <svg onClick={payClose} xmlns="http://www.w3.org000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-chevron-left" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
    </svg>
                    <p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800 mb-8 mt-14">Make the payment</p>
                   
                    
         
         
    
                    <div className="relative mx-auto mt-12" style={{width:'80%'}}>
    
                    <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}>Cloth price</dt>
                <dd class="text-base font-medium text-gray-900 ">{formatNumber(totalPrice)} ETB</dd>
              </dl>
          
              {
              afterDiscount &&
              <dl class="flex items-center justify-between gap-4">
              <dt class="text-base font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}>After Discount</dt>
            <div className="flex">
            <dd class="text-base font-medium text-gray-900 line">{formatNumber(afterDiscount)} ETB</dd>
            </div>              
            </dl>
            }
    
              
           
              <dl class="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 ">
              <dt class="text-base font-bold text-gray-900 ">Total Now</dt>
              <dd class="text-base font-bold text-gray-900 ">{formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : totalPrice)))} ETB</dd>
            </dl>
            <p className='ml-2 text-gray-600 par mt-5 mb-8 ' style={{fontSize:'13px'}}>
            Please proceed with completing the payment through our secure payment gateway for a safe and efficient transaction.</p>
    
    </div>
    <button
                   type='button'
                   onClick={goToPayment}
                   style={{fontSize:'12px',background:'black',height:'55px',width:'100%'}} disabled={loading}
                   className='flex justify-center items-center par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                   >
                  I understand
                 </button>
                       
                    </div>
                
                   
                     :
                     sendPaymentDetails === true &&
                     <div style={{width:'70vh',height:'90vh',overflowY:'scroll'}} class="bg-white shadow border rounded  w-full p-10 py-30 ">
                     <svg onClick={backSendPaymentDetails} xmlns="http://www.w3.org000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-chevron-left" viewBox="0 0 16 16">
       <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
     </svg>
                     <p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800 mb-8 mt-14">Make the payment</p>
                    
                     
          
          

 <div className="relative mx-auto my-10" style={{width:'80%'}}>
 <p style={{fontSize:'13px'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Pick Up Date</p>
 
 
   <input onChange={(e)=>deliveryDateSet(e)} style={{fontSize:'12px',height:'60px'}} datepicker type="date" class="w-full border  focus:outline-none
 focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
 border-gray-300 rounded-md capitaliz" placeholder="Select date"/>
 <div className={`${deliveryDateError.length !== 0 ? "block":"hidden"}`}>
 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org000/svg" fill="currentColor" viewBox="0 0 20 20">
 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
 </svg>
 <span className="sr-only">Info</span>
 <div className='Mooli'>
 {deliveryDateError}
 </div>
 </div>
 </div>
 </div>

 
{
  deliveryDate &&
  <PaymentComponent
  phoneNumber={phoneNumber}
  email={email}
  fullName={fullName}
  uniqueId={uniqueId}
  afterDiscount={afterDiscount}
  deliveryDate={deliveryDate}
  totalPrice={totalPrice}
  contactNumber={contactNumber}
  
  uid={uid}

  cart={cart}

  />
}
                        
                     </div> 

            
                 
                    
                    
            
                }
            </div>
        </div>
           }
      
            <Nav navigationPop={navPop} component={'Checkout'}/>
            <section class="bg-white py-8 antialiased  md:py-16">
  <div class="mx-auto max-w-screen-xl px-4 2xl:px-0">
{
  cart.length !=0 &&
  <h2 class="text-xl font-semibold text-gray-900  sm:text-2xl barlow mb-8 ml-4" style={{fontSize:'25px'}}>Shopping Cart</h2>

}
    <div class="  md: lg:flex lg:items-start xl:gap-8">
      <div class="mx-auto w-full flex-none lg:max-w-2xl xl:max-w-4xl">
        <div class="space-y-6">
            {
                cart.length === 0 &&
                <div className='flex items-center justify-center flex-col  '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/Add%20to%20Cart-amico.svg?alt=media&token=09878efb-c968-4931-a73d-7e721403588e" alt="Empty Cart" style={{width:"40%"}}/>
  <h1 className='fjalla text-center text-gray-900 mt-8 par' style={{fontSize:"20px"}}>Your Cart is Empty
  </h1>

<Link to={'/collection'}>
<button
                   type='button'
                   style={{fontSize:'14px',height:'53px'}}
                   className='par mt-12  w-full flex items-center text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                 >
                   Continue Shopping
                 </button></Link>

</div>
            }
            {
                cart.map(i=>{
                    return(
                        <div class="rounded-lg border border-gray-200 bg-white p-4 shadow-sm  md:p-6">
                        <div class="flex items-center w-full">
                        {i.clothData && i.clothData.clothPhotos && (
  <img
  style={{width:'120px',height:'120px'}}
    class=" rounded mr-5"
    src={i.clothData && i.clothData.clothPhotos[0]}
    alt="Cloth image"
  />
)}
            
            <div className='flex w-full justify-between'>

                          <div class="w-full  flex flex-col" >
                            <a href="#" class="text-base font-medium text-gray-900 hover:underline  header"> {i.clothData && i.clothData.clothTitle}</a>
                            <button onClick={()=>handleRemoveCart(i.id)} type="button" class="inline-flex items-center text-sm font-medium text-red-600 hover:underline ">
                                <svg class="me-1.5 h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 17.94 6M18 18 6.06 6" />
                                </svg>
                                Remove
                              </button>
                           
                          </div>
                          
                          <label for="counter-input" class="sr-only">Choose quantity:</label>
                          <div class="flex items-center justify-between  ">
                            <div class="flex items-center">
                              <button onClick={ ()=>handleDecrement(i.id)}type="button" id="decrement-button" data-input-counter-decrement="counter-input" class="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 ">
                                <svg class="h-2.5 w-2.5 text-gray-900 " aria-hidden="true" xmlns="http://www.w3.org000/svg" fill="none" viewBox="0 0 18 2">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16" />
                                </svg>
                              </button>
                              <input type="text" id="counter-input" data-input-counter class="w-10 shrink-0 border-0 bg-transparent text-center text-sm font-medium text-gray-900 focus:outline-none focus:ring-0 " placeholder="" value={itemQuantities[i.id] || 1} required />
                              <button onClick={ ()=>handleIncrement(i.id)} type="button" id="increment-button" data-input-counter-increment="counter-input" class="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 ">
                                <svg class="h-2.5 w-2.5 text-gray-900 " aria-hidden="true" xmlns="http://www.w3.org000/svg" fill="none" viewBox="0 0 18 18">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                                </svg>
                              </button>
                            </div>
                            <div class="text-end " >
                              <p class="break-keep font-bold text-gray-900 ">{roundToTwoDecimalPlaces(i.clothData && i.clothData.clothPrice * (itemQuantities[i.id] || 1))} ETB</p>
                          
                            </div>
                          </div>
            </div>

                        </div>
                      </div>
                    )
                })
            }
        
    
       
   
      
        </div>
      
      </div>

      <div class="mx-auto mt-6 max-w-4xl flex-1 space-y-6 lg:mt-0 lg:w-full">
        <div class="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm  sm:p-6">
          <p style={{fontSize:'18px'}} class="text-xl font-semibold text-gray-900 barlow">Order summary</p>

          <div class="space-y-4">
            <div class="space-y-2">
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500 "style={{fontSize:'12px',marginBottom:'5px'}}>Total Cloth price</dt>
                <dd class="text-base font-medium text-gray-900 ">{formatNumber(totalPrice)} ETB</dd>
              </dl>

              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500 "style={{fontSize:'12px',marginBottom:'5px'}}>Prepayment (ቅድመ ክፍያ)</dt>
                <dd class="text-base font-medium text-green-600">{formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : totalPrice)))} ETB</dd>
              </dl>

              
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500 "style={{fontSize:'12px',marginBottom:'5px'}}>After payment (ድህረ ክፍያ)</dt>
                <dd class="text-base font-medium text-green-600">{formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : totalPrice)))} ETB</dd>
              </dl>

              


           
            </div>

            <dl class="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 ">
              <dt class="text-base font-bold text-gray-900 ">Total Now</dt>
              <dd class="text-base font-bold text-gray-900 ">{formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : totalPrice)))} ETB</dd>
            </dl>
          </div>

{
  cart.length === 0 ?
            <button  style={{color:'white'}} class="text-white disabled flex w-full items-center justify-center rounded-lg bg-blue-800 px-5 py-4 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-primary-300  barlow">Proceed to Checkout</button>
:
          <button onClick={payPop} class="flex w-full items-center justify-center rounded-lg bg-blue-800 px-5 py-4 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-primary-300  barlow">Proceed to Checkout</button>
}
{
  discountCodePop === true ?
  <div className='flex justify-between w-full items-center'>
<input onChange={(e)=>discountCodeSet(e)} style={{fontSize:'12px',height:'50px'}}   required  autoComplete="off" placeholder="Discount Code" type="text" className="  focus:outline-none  w-full pt-5 pr-4 par pb-5 pl-4  mr-0 mb-0 ml-0 text-gray-700 block bg-white border-gray-300 rounded-md input-field-signUp" />
<a onClick={applyDiscountCode} style={{fontSize:'12px',height:'50px',width:'30%',color:'black',backgroundColor:'#31ffaa'}}class="ml-3 flex w-full items-center justify-center rounded-lg bg-blue-800 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-primary-300  ">Apply</a>

<p onClick={discountCodePopSet} className='ml-3 cursor-pointer'>x</p>
  </div>
:
cart.length === 0 ?
<a    style={{fontSize:'12px',height:'55px',backgroundColor:'#31ffaa',color:'black'}}class="disabled flex w-full items-center justify-center rounded-lg bg-blue-800 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-primary-300  border">Discount Code?</a>
:
<a   onClick={discountCodePopSet} style={{fontSize:'12px',height:'55px',backgroundColor:'#31ffaa',color:'black'}}class="flex w-full items-center justify-center rounded-lg bg-blue-800 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-primary-300  border">Discount Code?</a>

}
          <div class="flex items-center justify-center gap-2">
            <span class="text-sm font-normal text-gray-500 "style={{fontSize:'12px',marginBottom:'5px'}}> or </span>
       <Link to={'/collection'}>
       <button style={{fontSize:'11px'}} class="barlow py-4 inline-flex items-center gap-2 text-sm font-medium text-primary-700 underline hover:no-underline ">
              Continue Shopping
              <svg class="h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org000/svg" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4" />
              </svg>
            </button></Link>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>

        </div>
        <div id='mobile'>
           {
                paymentStatus?.data?.status === 'success'?
                <div class="h-full   w-full  px-4 pb-10 paymentPop" style={{zIndex:'10000'}}>
<div style={{width:'70vh',height:'90vh'}} class="flex-col bg-white shadow border rounded  w-full p-10 py-30 flex justify-center items-center">

<svg xmlns="http://www.w3.org000/svg" width="80" height="80" fill="currentColor" className="bi bi-check-circle-fill text-green-500" viewBox="0 0 16 16">
<path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"/>
</svg>
<h1 className='nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 color-var(--podium-cds-color-text-primary) weight-medium uppercase mt-3 text-center' style={{fontSize:'28px'}}>Thank you for placing your order</h1>
<p className='par text-gray-600 text-center mt-2' style={{fontSize:'12px'}}>Once the receipt has been verified, our representative will contact you to confirm the order.</p>

<Link to={`/orders/${uniqueId}`}>
<button

      type='button'
      
      style={{fontSize:'12px',background:'black',height:'55px',width:'100%'}}
      className='flex justify-center items-center par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
    >
     Track Order
    </button></Link>
     
     
       </div> 
      
     </div>
     :
     paymentStatus?.data?.status.includes('pending')?
     <div class="h-full   w-full  px-4 pb-10 paymentPop" style={{zIndex:'10000'}}>
<div style={{width:'70vh',height:'90vh'}} class="flex-col bg-white shadow border rounded  w-full p-10 py-30 flex justify-center items-center">

<div className=' p-5 '>
<img width="70" height="70" src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/close.png?alt=media&token=44ab7ef9-da9d-4a0a-838f-881504dcafc3'/>

</div>
<h1 className='nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 color-var(--podium-cds-color-text-primary) weight-medium uppercase mt-3 text-center' style={{fontSize:'28px'}}>Payment Failed</h1>
<p className='par text-gray-600 text-center mt-2' style={{fontSize:'12px'}}>We've encountered an error with the transaction, either it's been tried before and failed or there's an internal error. Please try again later </p>

<Link to={`/collection/${uid + v4().slice(0,30)}/checkout`}>
<button

type='button'

style={{fontSize:'12px',background:'black',height:'55px',width:'100%'}}
className='flex justify-center items-center par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
>
Retry
</button></Link>


</div> 

</div>
:
         paymentStatus?.data?.status.includes('failed/')?
         <div class="h-full   w-full  px-4 pb-10 paymentPop" style={{zIndex:'10000'}}>
<div style={{width:'70vh',height:'90vh'}} class="flex-col bg-white shadow border rounded  w-full p-10 py-30 flex justify-center items-center">

<div className=' p-5 '>
<img width="70" height="70" src='https://firebasestorage.googleapis.com/v0/b/portfolio-47779.appspot.com/o/close.png?alt=media&token=44ab7ef9-da9d-4a0a-838f-881504dcafc3'/>

</div>
<h1 className='nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 color-var(--podium-cds-color-text-primary) weight-medium uppercase mt-3 text-center' style={{fontSize:'28px'}}>Payment Failed</h1>
<p className='par text-gray-600 text-center mt-2' style={{fontSize:'12px'}}>We've encountered an error with the transaction, either it's been tried before and failed or there's an internal error. Please try again later </p>

<Link to={`/collection/${uid + v4().slice(0,30)}/checkout`}>
<button

type='button'

style={{fontSize:'12px',background:'black',height:'55px',width:'100%'}}
className='flex justify-center items-center par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
>
Retry
</button></Link>


</div> 

</div>
       :
            pay=== true &&
            <div class="h-full   w-full  px-4 pb-10 paymentPop" style={{zIndex:'10000'}}>

            <div class="flex flex-col items-center justify-center">
                {
                 
                    loading === true ?
                <div style={{width:'95%',height:'70vh'}} class="bg-white flex-col shadow border rounded  w-full p-10 py-30 flex justify-center items-center">
                    <div
                      className=" h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-e-transparent align-[-0.125em] text-surface motion-reduce:animate-[spin_1.5s_linear_infinite] "
                      role="status"></div>
                      {
                        progress !=0 && progress !== 100 &&
                        <div className=" bg-gray-200 rounded-full  mt-5">
                        <div className=" text-xs font-medium text-gray-800 text-center p-0.5 leading-none rounded-full" style={{width:progress,background:'#31ffaa'}}> {progress}%</div>
                      </div>
                      }
                  
                    </div>
                    :
                   
                     paymentannounce === true ?
                    <div style={{width:'95%',height:'70vh'}} class="bg-white shadow border rounded  w-full p-10 py-30 ">
                    <svg onClick={payClose} xmlns="http://www.w3.org000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-chevron-left" viewBox="0 0 16 16">
      <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
    </svg>
                    <p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800 mb-8 mt-14">Make the payment</p>
                   
                    
         
         
    
                    <div className="relative mx-auto mt-12" style={{width:'80%'}}>
    
                    <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}>Cloth price</dt>
                <dd class="text-base font-medium text-gray-900 ">{formatNumber(totalPrice)} ETB</dd>
              </dl>
          
              {
              afterDiscount &&
              <dl class="flex items-center justify-between gap-4">
              <dt class="text-base font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}>After Discount</dt>
            <div className="flex">
            <dd class="text-base font-medium text-gray-900 line">{formatNumber(afterDiscount)} ETB</dd>
            </div>              
            </dl>
            }
    
              
           
              <dl class="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 ">
              <dt class="text-base font-bold text-gray-900 ">Total Now</dt>
              <dd class="text-base font-bold text-gray-900 ">{formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : totalPrice)))} ETB</dd>
            </dl>
            <p className='ml-2 text-gray-600 par mt-5 mb-8 ' style={{fontSize:'13px'}}>
            Please proceed with completing the payment through our secure payment gateway for a safe and efficient transaction.</p>
    
    </div>
    <button
                   type='button'
                   onClick={goToPayment}
                   style={{fontSize:'12px',background:'black',height:'55px',width:'100%'}} disabled={loading}
                   className='flex justify-center items-center par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                   >
                  I understand
                 </button>
                       
                    </div>
                  
                     :
                     cbebirr === true ?
                      <div style={{width:'90vw',height:'70vh'}} class="bg-white shadow border rounded  w-full p-5 py-30 ">
                     <svg onClick={backCbeBirr} xmlns="http://www.w3.org000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-chevron-left" viewBox="0 0 16 16">
       <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
     </svg>
                     <p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800 mb-8 mt-14">Make the payment</p>
                    
                     
          
          
     
                     <div className="relative mx-auto mt-12" style={{width:'80%'}}>
     
           
     
               
               <dl class="flex items-center justify-between gap-4">
                 <dt class="text-base font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}>Account Number</dt>
                 <dd class="text-base font-medium text-green-600 par" style={{fontSize:'13px'}}>{chosenMethod === 'Telebirr'? '0991440729': chosenMethod === 'Cbe Birr'? '0991440729': '1000097555064'}</dd>
               </dl>
               <dl class="flex items-center justify-between gap-4">
                 <dt class="text-base font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}>Account Holder</dt>
                 <dd class="text-base font-medium text-green-600  par" style={{fontSize:'13px'}}>Aster Omer </dd>
               </dl>
               {
                  afterDiscount &&
                  <dl class="flex items-center justify-between gap-4">
                  <dt class="text-base font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}>Total Price</dt>
                  <dd class="text-base font-medium text-green-600  par" style={{fontSize:'13px'}}>{formatNumber(totalPrice)} ETB</dd>
                </dl>
              }
              {
                  afterDiscount &&
                  <dl class="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 ">
                  <dt class="text-base font-bold text-gray-900 ">Discount</dt>
                  <dd class="text-base font-bold text-gray-900 " >-{afterDiscount && formatNumber(totalPrice - afterDiscount) } ETB</dd>
                </dl>
              }
               <dl class="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 ">
               <dt class="text-base font-bold text-gray-900 ">Total Now</dt>
               <dd class="text-base font-bold text-gray-900 " >{formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : totalPrice)))} ETB</dd>
             </dl>
            <p className='ml-2 text-gray-600 par mt-5 mb-8 ' style={{fontSize:'13px'}}>

            You must transfer {formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : totalPrice)))} ETB to {chosenMethod === 'Telebirr'? '0991440729': chosenMethod === 'Cbe Birr'? '0991440729': '1000097555064'} via {chosenMethod}. You must provide us with a screenshot of the transaction as proof.</p>
     
     </div>
     <button
                    type='button'
                    onClick={goToSendPaymentDetails}
                    style={{fontSize:'12px',background:'black',height:'55px',width:'100%'}} disabled={loading}
                    className='flex justify-center items-center par mt-6  w-full text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                     >
                  I have transferred the money
                  </button>
                        
                     </div>
                     :
                     sendPaymentDetails === true &&
                     <div style={{width:'90vw',height:'70vh',overflowY:'scroll'}} class="bg-white shadow border rounded  w-full p-10 py-30 ">
                     <svg onClick={backSendPaymentDetails} xmlns="http://www.w3.org000/svg" width="20" height="20" fill="currentColor" class="cursor-pointer bi bi-chevron-left" viewBox="0 0 16 16">
       <path fill-rule="evenodd" d="M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0"/>
     </svg>
                     <p style={{fontSize:'20px'}} class="text-center focus:outline-none text-2xl font-extrabold leading-6 text-gray-800 mb-8 mt-14">Make the payment</p>
                    
                     
          
          
    
 <div className="relative mx-auto my-10" style={{width:'100%'}}>
 <p style={{fontSize:'3vw'}} className="par bg-white pt-0 pr-2 pb-0 pl-2 -mt-3 mr-0 mb-0 ml-2 font-medium text-gray-600 absolute">Pick Up Date</p>
 
 
   <input onChange={(e)=>deliveryDateSet(e)} style={{fontSize:'12px',height:'60px',color:'black'}} datepicker type="date" class="w-full border  focus:outline-none
 focus:border-black w-full pt-5 pr-4 par pb-5 pl-4 mt-2 mr-0 mb-0 ml-0 text-gray-700 block bg-white
 border-gray-300 rounded-md capitaliz" placeholder="Select date"/>
 <div className={`${deliveryDateError.length !== 0 ? "block":"hidden"}`}>
 <div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
 <svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org000/svg" fill="currentColor" viewBox="0 0 20 20">
 <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
 </svg>
 <span className="sr-only">Info</span>
 <div className='Mooli'>
 {deliveryDateError}
 </div>
 </div>
 </div>
 </div>

 
 {
  deliveryDate &&
  <PaymentComponent
  phoneNumber={phoneNumber}
  email={email}
  fullName={fullName}
  uniqueId={uniqueId}
  afterDiscount={afterDiscount}
  deliveryDate={deliveryDate}
  totalPrice={totalPrice}
  contactNumber={contactNumber}
  
  uid={uid}

  cart={cart}

  />
}
                        
                     </div> 

            
                 
                    
                    
            
                }
            </div>
        </div>
           }
      
            <Nav navigationPop={navPop} component={'Checkout'}/>
            <section class="bg-white py-8 antialiased  md:py-16">
  <div class="mx-auto max-w-screen-xl px-4 2xl:px-0">
{
  cart.length !=0 &&
  <h2 class="text-xl font-semibold text-gray-900  sm:text-2xl barlow mb-8 ml-4" style={{fontSize:'25px'}}>Shopping Cart</h2>

}
    <div class="  md: lg:flex lg:items-start xl:gap-8">
      <div class="mx-auto w-full flex-none lg:max-w-2xl xl:max-w-4xl">
        <div class="space-y-6">
            {
                cart.length === 0 &&
                <div className='flex items-center justify-center flex-col  '>
<img src="https://firebasestorage.googleapis.com/v0/b/shewit-18d54.appspot.com/o/Add%20to%20Cart-amico.svg?alt=media&token=09878efb-c968-4931-a73d-7e721403588e" alt="Empty Cart" style={{width:"40%"}}/>
  <h1 className='fjalla text-center text-gray-900 mt-8 par' style={{fontSize:"20px"}}>Your Cart is Empty
  </h1>

<Link to={'/collection'}>
<button
                   type='button'
                   style={{fontSize:'14px',height:'53px'}}
                   className='par mt-12  w-full flex items-center text-white  bg-gray-800 hover:bg-gray-900 fjalla focus:ring-4 focus:ring-white   rounded-lg text-sm px-5 py-2.5  mb-2  focus:outline-none '
                 >
                   Continue Shopping
                 </button></Link>

</div>
            }
            {
                cart.map(i=>{
                    return(
                        <div class="rounded-lg border border-gray-200 bg-white p-4 shadow-sm  md:p-6">
                        <div class="flex items-center w-full">
                        {i.clothData && i.clothData.clothPhotos && (
  <img
  style={{width:'25vw',height:'25vw'}}
    class=" rounded mr-5"
    src={i.clothData && i.clothData.clothPhotos[0]}
    alt="Cloth image"
  />
)}
            
            <div className='flex w-full justify-between'>

                          <div class="w-full  flex flex-col" >
                            <a style={{fontSize:'3.5vw'}}class="text-base font-medium text-gray-900 hover:underline  header"> {i.clothData && i.clothData.clothTitle}</a>
                            <button  style={{fontSize:'3vw'}}onClick={()=>handleRemoveCart(i.id)} type="button" class="inline-flex items-center text-sm font-medium text-red-600 hover:underline ">
                                <svg class="me-1.5 h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18 17.94 6M18 18 6.06 6" />
                                </svg>
                                Remove
                              </button>
                           
                          </div>
                          
                          <label for="counter-input" class="sr-only">Choose quantity:</label>
                          <div class="flex flex-col  ">
                          
                            <div class="text-end " >
                              <p style={{fontSize:'3.5vw'}}class="break-keep font-bold text-gray-900 barlow">{roundToTwoDecimalPlaces(i.clothData && i.clothData.clothPrice * (itemQuantities[i.id] || 1))} ETB</p>
                          
                            </div>
                            <div class="flex items-center">
                              <button onClick={ ()=>handleDecrement(i.id)}type="button" id="decrement-button" data-input-counter-decrement="counter-input" class="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 ">
                                <svg class="h-2.5 w-2.5 text-gray-900 " aria-hidden="true" xmlns="http://www.w3.org000/svg" fill="none" viewBox="0 0 18 2">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h16" />
                                </svg>
                              </button>
                              <input type="text" id="counter-input" data-input-counter class="w-10 shrink-0 border-0 bg-transparent text-center text-sm font-medium text-gray-900 focus:outline-none focus:ring-0 " placeholder="" value={itemQuantities[i.id] || 1} required />
                              <button onClick={ ()=>handleIncrement(i.id)} type="button" id="increment-button" data-input-counter-increment="counter-input" class="inline-flex h-5 w-5 shrink-0 items-center justify-center rounded-md border border-gray-300 bg-gray-100 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-100 ">
                                <svg class="h-2.5 w-2.5 text-gray-900 " aria-hidden="true" xmlns="http://www.w3.org000/svg" fill="none" viewBox="0 0 18 18">
                                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 1v16M1 9h16" />
                                </svg>
                              </button>
                            </div>
                          </div>
            </div>

                        </div>
                      </div>
                    )
                })
            }
        
    
       
   
      
        </div>
      
      </div>

      <div class="mx-auto mt-6 w-full flex-1 space-y-6 ">
        <div class="space-y-4 rounded-lg border border-gray-200 bg-white p-4 shadow-sm  sm:p-6">
          <p class="text-xl font-semibold text-gray-900 ">Order summary</p>

          <div class="space-y-4">
            <div class="space-y-2">
              <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}>Cloth Price</dt>
                <dd class="text-base font-medium text-gray-900 ">{formatNumber(totalPrice) ? formatNumber(totalPrice) : 0} ETB</dd>
              </dl>
            {
              afterDiscount &&
              <dl class="flex items-center justify-between gap-4">
              <dt class="text-base font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}>After Discount</dt>
            <div className="flex">
            <dd class="text-base font-medium text-gray-900 line">{formatNumber(afterDiscount)} ETB</dd>
            </div>              
            </dl>
            }

              {/* <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}>Prepayment (ቅድመ ክፍያ)</dt>
                <dd class="text-base font-medium text-green-600">{formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : afterDiscount ? afterDiscount : totalPrice))) ? formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : afterDiscount ? afterDiscount : totalPrice))) : 0} ETB</dd>
              </dl> */}

              
              {/* <dl class="flex items-center justify-between gap-4">
                <dt class="text-base font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}>After payment (ድህረ ክፍያ)</dt>
                <dd class="text-base font-medium text-green-600">{formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : totalPrice)))? formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : totalPrice))): 0} ETB</dd>
              </dl> */}

              


            </div>

            <dl class="flex items-center justify-between gap-4 border-t border-gray-200 pt-2 ">
              <dt class="text-base font-bold text-gray-900 ">Total Now</dt>
              <dd class="text-base font-bold text-gray-900 ">{formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : totalPrice)))? formatNumber(formatNumber(roundToTwoDecimalPlaces(afterDiscount ? afterDiscount : totalPrice))):0} ETB</dd>
            </dl>
          </div>

{
  cart.length === 0 ?
            <a  style={{color:'white'}} class="text-white disabled flex w-full items-center justify-center rounded-lg bg-blue-800 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-primary-300  ">Proceed to Checkout</a>
:
          <a onClick={payPop} style={{fontSize:'12px',height:'55px'}}class="flex w-full items-center justify-center rounded-lg bg-blue-800 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-primary-300  ">Proceed to Checkout</a>
}
{
  discountCodePop === true ?
  <div className='flex justify-between w-full items-center'>
<input onChange={(e)=>discountCodeSet(e)} style={{fontSize:'12px',height:'50px'}}   required  autoComplete="off" placeholder="Discount Code" type="text" className="  focus:outline-none  w-full pt-5 pr-4 par pb-5 pl-4  mr-0 mb-0 ml-0 text-gray-700 block bg-white border-gray-300 rounded-md input-field-signUp" />
<a onClick={applyDiscountCode} style={{fontSize:'12px',height:'50px',width:'30%',color:'black',backgroundColor:'#31ffaa'}}class="ml-3 flex w-full items-center justify-center rounded-lg bg-blue-800 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-primary-300  ">Apply</a>

<p onClick={discountCodePopSet} className='ml-3 cursor-pointer'>x</p>
  </div>
:
cart.length === 0 ?
<a    style={{fontSize:'12px',height:'55px',backgroundColor:'#31ffaa',color:'black'}}class="disabled flex w-full items-center justify-center rounded-lg bg-blue-800 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-primary-300  border">Discount Code?</a>
:
<a   onClick={discountCodePopSet} style={{fontSize:'12px',height:'55px',backgroundColor:'#31ffaa',color:'black'}}class="flex w-full items-center justify-center rounded-lg bg-blue-800 px-5 py-2.5 text-sm font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-4 focus:ring-primary-300  border">Discount Code?</a>

}
<div className={`${discountCodeError === "error" ? "block":"hidden"}`}>
<div className="flex items-center p-4 mb-4 text-sm text-red-800 border border-red-300 rounded-lg bg-red-50 " role="alert">
<svg className="flex-shrink-0 inline w-4 h-4 mr-3" aria-hidden="true" xmlns="http://www.w3.org000/svg" fill="currentColor" viewBox="0 0 20 20">
<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
</svg>
<span className="sr-only">Info</span>
<div className='Mooli'>
Discount Code can't be empty
</div>
</div>
</div>

          <div class="flex items-center justify-center gap-2">
            <span class="text-sm font-normal text-gray-500 "style={{fontSize:'13px',marginBottom:'2px'}}> or </span>
       <Link to={'/collection'}>
       <a title="" class="inline-flex items-center gap-2 text-sm font-medium text-primary-700 underline hover:no-underline dark:text-primary-500">
              Continue Shopping
              <svg class="h-5 w-5" aria-hidden="true" xmlns="http://www.w3.org000/svg" fill="none" viewBox="0 0 24 24">
                <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 12H5m14 0-4 4m4-4-4-4" />
              </svg>
            </a></Link>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>

        </div>
    </div>
:
<NotFound/>
    

    )
  })
  )
}
