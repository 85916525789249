import React, { Fragment, useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from 'react-router-dom/cjs/react-router-dom'
import Hero8 from '../views/Hero8.js'
import Stats2 from '../views/Stats2.js'
import Logos1 from '../views/logos.js'
import Features1 from '../views/features1.js'
import Team1 from '../views/Team1.js'
import './quote.css'
import Navigation from '../views/Navigation.js'
import Footer from '../views/Footer.js'
import Contact8 from './contact8.js'

export default function About() {
    const [navPop,setNavPop] = useState(false)
    function navPopHandler(){
      if(navPop === true){
        setNavPop(false)
      }
      else{
        setNavPop(true)
      }
    } 
  return (
    <div>   <Helmet>
    <title>About Us: Ephratawi Design</title>
    <meta property="og:title" content="Ephratawi Design" />
  </Helmet>
<Navigation navigationPop={navPop} component='About'/>
          <div className="about-container1">
  
            <h1 
                   style={{fontSize:'55px',margin:'60px 0 20px 0'}}       className="nds-text _1MF91zHG css-7vq5hu e1yhcai00 appearance-display2 text-white weight-medium uppercase"

            >
              <span>
              Welcome to
             
              </span>
              <span className="text-[#4133ff]"> Ephratawi Designs</span>
            </h1>
 
    
    
    
   
    
            <span className="about-text130 px-10">
            Welcome to Ephratawi Designs, a proudly Ethiopian brand based in the heart of Addis Ababa. We specialize in creating affordable, high-quality Habesha clothing that beautifully combines the timeless elegance of tradition with the fresh flair of modern fashion. Our collection is designed to reflect the rich cultural heritage of Ethiopia while embracing contemporary styles to suit today's fashion-forward individuals.

At Ephratawi Designs, we believe that clothing is not just about fashion—it’s about expressing identity, pride, and cultural heritage. That’s why we meticulously craft each piece using the finest materials, ensuring both comfort and durability. Whether it's a classic Habesha kemis or a modern reinterpretation of traditional designs, we strive to offer garments that make you feel both confident and connected to the beauty of Ethiopian culture.

Our wide range of styles, colors, and sizes ensures that there is something for everyone, from those seeking elegant, everyday wear to those looking for statement pieces for special occasions. We are committed to offering designs that cater to every taste and budget, making it easy for you to find the perfect outfit without compromising on quality.

At Ephratawi Designs, our mission is to celebrate and preserve the beauty of Habesha fashion while making it accessible and relevant in today's global fashion landscape. We are proud to bring you unique, locally-made clothing that you can wear with pride, knowing that each piece is created with care, craftsmanship, and a deep love for Ethiopian culture.
            </span>

    </div>
<Contact8 comp='About'/>


    <Footer/>
          
          </div>
  )
}
